import React from 'react';
import TopLayout from './TopLayout';
import '../../src/assets/css/index.css'

export const wrapRootElement = ({ element }: { element: React.ReactNode }) => {

  return (
        <TopLayout>{element}</TopLayout>
  );
};
