import React, {ReactNode} from 'react';
import {Helmet} from 'react-helmet';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import 'assets/css/index.css';

import 'swiper/css/swiper.min.css';
import 'aos/dist/aos.css';

interface TopLayoutProps {
    children: ReactNode;
}

export default function TopLayout(props: TopLayoutProps) {
    return (
        <>
            <Helmet>
                <title>Better Nerf Technology</title>
                <meta charSet="utf-8"/>
                <meta
                    name="viewport"
                    content="minimum-scale=1, initial-scale=1, width=device-width"
                />
                <link rel="shortcut icon" href="./../../src/assets/favicon.svg/"/>
                <meta name="theme-color" content="#ffffff"/>
                <meta
                    name="description"
                    content="Better Nerf Technology | Dostarczamy najlepsze rozwiązania dla klientów podązających za jakością i innowacyjnością. Współpracujemy z najlepszymi dostawcami usług IT na świecie. Wspieramy naszych klientów w rozwoju ich biznesu. Analityka danych, oprogramowanie na zamówienie, chmura, AI."
                />
                <meta
                    name="robots"
                    content="max-snippet:-1, max-image-preview:large, max-video-preview:-1"
                />
                <meta property="og:locale" content="en_US"/>
                <meta property="og:type" content="website"/>
                <meta
                    property="og:image"
                    content="./../../src/assets/favicon.svg"
                />
                <meta
                    property="og:title"
                    content="Better Nerf Technology"
                />
                <meta
                    property="og:description"
                    content="Better Nerf Technology | Dostarczamy najlepsze rozwiązania dla klientów podązających za jakością i innowacyjnością. Współpracujemy z najlepszymi dostawcami usług IT na świecie. Wspieramy naszych klientów w rozwoju ich biznesu. Analityka danych, oprogramowanie na zamówienie, chmura, AI."
                />
                <meta property="og:url" content="https://bntech.dev"/>
                <link
                    href="https://fonts.googleapis.com/css?family=Lato:100,100i,300,300i,400,400i,700,700i,900,900i&display=swap"
                    rel="stylesheet"
                />
                <link
                    rel="stylesheet"
                    href="./index.css"/>
                <script
                    src="https://kit.fontawesome.com/4c273e6d43.js"
                    crossOrigin="anonymous"
                ></script>
            </Helmet>
            {props.children}
        </>
    );
}

