module.exports = [{
      plugin: require('../plugins/gatsby-plugin-top-layout/gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/assets/images/white.svg","name":"Better Nerf Technology | Dostarczamy najlepsze rozwiązania dla klientów podązających za jakością i innowacyjnością. Współpracujemy z najlepszymi dostawcami usług IT na świecie. Wspieramy naszych klientów w rozwoju ich biznesu.","short_name":"BNTech","display":"standalone","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"c069bb24a3cf3b129afd0f96577f1c24"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-YXLELEBGEL"],"gtagConfig":{"optimize_id":"OPT_CONTAINER_ID","anonymize_ip":true,"cookie_expires":0},"pluginConfig":{"head":true,"respectDNT":true,"exclude":[],"origin":"https://www.googletagmanager.com","delayOnRouteUpdate":0}},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
